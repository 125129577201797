import React, { useState } from 'react';

import Logo from "../assets/bidBooster.png";
import Hi9Logo from "../assets/Hi9_logo.png";

import { useNavigate } from "react-router-dom";

import { auth, firebase } from '../firebase';
import StyledFirebaseAuth from '../firebase/StyledFirebaseAuth';
import { loginStorage, logoutStorage, save } from "../utils/storage";

import { Typography, Box, Link, CircularProgress } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import { login } from '../api';
const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    backgroundColor: "#f5f4f4",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    margin: "10px 0",
    width: 150,
  },
  title: {
    fontWeight: 500,
    margin: "15px 0",
  },
  progressWrapper: {
    minHeight: 250,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
}))

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [waitlistMessage, setWaitlistMessage] = useState<string | null>(null)

  const authUIConfig = {
    signInFlow: 'popup',
    tosUrl: 'https://bidbooster.co.uk/terms',
    privacyPolicyUrl: function () {
      window.location.assign('https://bidbooster.co.uk/privacy');
    },
    // signInSuccessUrl: "/",
    callbacks: {
      signInSuccessWithAuthResult: async (authResult: any) => {
        console.log("authResult", authResult)


        const token = await authResult.user.getIdToken()
        console.log("token", token)
	if (!token) throw new Error("No token found")
	if (typeof token !== "string") throw new Error("Token is not a string")

        setLoading(true)
        const { waitlist, firstTime } = await login({}, token)
        setLoading(false)

        if (waitlist) {
          console.log("waitlist")
          if (firstTime) setWaitlistMessage("Thank you for joining the waitlist.")
          else setWaitlistMessage("You are still in the waiting list.")

          await auth.signOut()
        } else {
          const { user } = authResult
          loginStorage("user", user)
          save("user-token", {token})
          navigate("/")
        }

        return false
      },
    },
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ]
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.loginWrapper}>
        <img src={Logo} alt="Debbie" className={classes.logo} onClick={() => { navigate("/") }} style={{cursor: "pointer"}}/>
        {!waitlistMessage && <Typography variant="h5" className={classes.title}>
          Login
        </Typography>}
      </Box>

      {!waitlistMessage && !loading &&
        <StyledFirebaseAuth uiConfig={authUIConfig} firebaseAuth={auth} />}

      {waitlistMessage && <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ padding: 10, mt: 3, background: "white" }}>
          {waitlistMessage}
          <br />
          <br />
          You will receive an email when your account becomes active.
        </Typography>
      </Box>}

      {loading && <Box className={classes.progressWrapper}>
        <CircularProgress />
      </Box>}

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 0.5, mt: 5 }}>
        <Typography variant="subtitle2" sx={{ color: "black" }}>Built by</Typography>
        <Link href="https://hi9.io" target="_blank">
          <img src={Hi9Logo} alt="Hi9" width="40" style={{cursor: "pointer"}}/>
        </Link>
      </Box>
    </Box>
  );
};
