import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import {
  Box, CircularProgress, Alert, Typography,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { getUser, load, setUser } from '../utils/storage'
import { connectStripe } from '../utils/stripe'

export default function AfterPayment() {
  const navigate = useNavigate();

  const [plan, setPlan] = useState("")

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    updateSetter()
  }, [])
  function parseJwt (token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const updateSetter = async () => {
    console.log("updateSetter")
    setLoading(true)
    setError(null)

    const params = new URLSearchParams(window.location.search)
    const stripe = params.get("stripe")
    const plan = (params.get("plan") || "").split("-")[0]
    const interval = (params.get("plan") || "").split("-")[1] || "month"

    setPlan(plan)

    const user = getUser()

    console.log("stripe", stripe)
    console.log("plan", plan)
    console.log("user", user)

    if (user && stripe && plan) {

      try {
        if (!user) throw new Error("Not logged in")
        const {token} = load("user-token", user)
        const {user_id}   = parseJwt(token)
	console.log("uid", user_id)
	if (!token) throw new Error("Not logged in or no token")

        const result = await connectStripe({
          plan,
          checkout: stripe,
          user: user_id,
        }, token)
	console.log("result", result)

        setLoading(false)
        // Update local storage with new plan
        setUser({ ...(getUser() || {}), plan: result.plan, interval: result.interval || interval })
      } catch (error: any) {
        setLoading(false)
        setError(error.message)
      }
    } else {
      setLoading(false)
      setError("Invalid URL" as any)
    }
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flex: "1", width: "100%", pt: 10 }}>
      {loading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box>
          <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
          <Typography onClick={() => { updateSetter() }}>
            Try again
          </Typography>
          or

          <Typography onClick={(e) => {
            e.preventDefault();
            window.location.href = "mailto:wo@hi9.io";
          }}>
            Contact us
          </Typography>

        </Box>
      ) : (
        <Box>
          <Alert severity="success" sx={{ mb: 1 }}>Success!</Alert>
          You have chosen the {plan} plan.

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 3 }}
            onClick={() => { navigate("/setter") }}
          >
            <Typography variant="subtitle2">
              Go to home
            </Typography>
            <ArrowForwardIcon sx={{ fontSize: 14, ml: 0.5 }} />
          </Box>
        </Box>
      )}
    </Box>
  )
}
