import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom';

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import defaultTheme from "./theme";
import { Box } from '@mui/material';

import Home from "./pages/Home";
import Home2 from "./pages/Home2";
import Login from './pages/Login';
// import Plan from './pages/Plan';
import Plans from './pages/Plans';
import AfterPayment from './pages/AfterPayment';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(defaultTheme)}>
        <CssBaseline />
        <Box className="App">
          <Router>
            <Routes>
              <Route exact-path path="/" element={<Home/>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/plans" element={<Plans />} />
              <Route path="/after-payment" element={<AfterPayment />} />
              <Route path="/application_question" element={<Home2 />} />

              <Route path='*' element={<Box>Not found</Box>} />
            </Routes>
          </Router>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
