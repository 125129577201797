import { TypeOfPlans } from "../pages/pricingTable"

export type User = {
  id: string
  plan: TypeOfPlans
  interval: "month" | "year"
  token: string
}

export function setUser(user: User | null) {
  localStorage.setItem("user", JSON.stringify(user))
}
export function save(where: string, what: object) {
  localStorage.setItem(where, JSON.stringify(what))
}
export function load(where: string, what: object) {
  return JSON.parse(localStorage.getItem(where) || JSON.stringify(what))
}
const DEFAULT_KEY = "user"

export const loginStorage = (key: string = DEFAULT_KEY, data: User) => {
  localStorage.setItem(key, JSON.stringify(data))
};

export const logoutStorage = (key: string = DEFAULT_KEY, redirect = false) => {
  localStorage.removeItem(key)

  if (redirect) window.location.replace("/")
}

export const getUser = (key: string = DEFAULT_KEY): User => {
  const user = localStorage.getItem(key)
  if (user) {
      return {plan : "free", id : "", interval: "month", token: "", ...JSON.parse(user) }
  }
  return {interval: "month", plan: "free", id: "", token: ""}
}
export const getStorage = (key: string = DEFAULT_KEY) => {
  const data = localStorage.getItem(key)
  return data ? JSON.parse(data as string) : null
};
