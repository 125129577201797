/* 
Using compat instead of modular because the latter is not compatible with firebaseui
*/

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

require('dotenv').config()

const firebaseConfig = {
  apiKey: "AIzaSyBZzfkbwKZDs7Y-kldUDL0tCNA5mVFyE9c",
  authDomain: "bidbooster.firebaseapp.com",
  projectId: "bidbooster",
  storageBucket: "bidbooster.appspot.com",
  messagingSenderId: "583532635602",
  appId: "1:583532635602:web:8d1ec93e1d7a52d46d140b"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const fs = firebase.firestore();
/* Functions */
const fetchHistory = async (uid: string) => {
  try {
    const collectionRef = fs.collection('history').doc(uid).collection('chats');
    const snapshot = await collectionRef.orderBy('timestamp', 'desc').get();
    const data = snapshot.docs.map((doc) => doc.data());
    console.log(data);
    return data
  } catch (error) {
    console.log(error);
  }
};

const handleChat = async (uid: string, chatId: string, message: any) => {
  try {
    const chatRef = fs.collection('history').doc(uid).collection('chats').doc(chatId);
    const chatDoc = await chatRef.get();

    if (chatDoc.exists) {
      // Chat ID exists, add message to the list of messages
      await chatRef.update({
        messages: firebase.firestore.FieldValue.arrayUnion({
          timestamp: new Date(),
          ...message,
        }),
      });
    } else {
      // Chat ID doesn't exist, create a new chat document
      await chatRef.set({
        chatId: chatId,
        timestamp: new Date(),
        messages: [
          {
            timestamp: new Date(),
            ...message,
          },
        ],
      });
    }

    return (await chatRef.get()).data()
  } catch (error) {
    console.log(error);
  }
};

export const logOut = () => {
  firebase.auth().signOut();
};

export const auth = firebase.auth();

export {
  firebase, app,
  fetchHistory,
  handleChat,
}
