import axios from 'axios'

export async function askBeam(data: any, api: string = 'https://europe-west2-beam-383508.cloudfunctions.net/bidbooster_v1?') {
  const params = new URLSearchParams(data).toString()
  const url = api + params
  // const url = "http://127.0.0.1:8080?" + params

  try {
    const response = await axios.post(url, data)
    // console.log(response.data);
    console.log("200", response.data);
    if (response.data.message === "'Out of Usage limit") {
      return {
        answer: "Out of Usage limit", // <a href='plans'>Upgrade</a>",
        details:[],
        memory: [],
        memory_question: [],
        time: 0,
        prompt: "",
      }
    }
    return response.data.result
  } catch (error: any) {
    console.error(error)
    throw new Error(error.message)
  }
}

export async function login(data: any, token: string) {
  const url = "https://authuser-bv6fvkpjpa-uc.a.run.app"
  // const url = "http://127.0.0.1:5001/beam-383508/europe-west2/authUser"

  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    return response.data.data
  } catch (error: any) {
    console.error(error)
    throw new Error(error.response.data.message)
  }
}
