import axios from 'axios'



export async function updateSubscription(data: any, token: string) {
  const url = "https://europe-west2-beam-383508.cloudfunctions.net/updateSubscriptionV2"
  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    // console.log(response.data);
    return response.data
  } catch (error: any) {
    console.error(error)
    throw new Error(error.response.data.message)
  }
}

export async function connectStripe(data: any, token: string) {
  const url = "https://europe-west2-beam-383508.cloudfunctions.net/connectStripeV2"
  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    console.log(response);
    return response.data
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response.data.message)
  }
}
