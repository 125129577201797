import React, { useEffect, useState } from 'react';

import Logo from "../assets/bidBooster.png";

import {
  Box, Typography, TextField, Drawer, CircularProgress, Link, Icon, Menu, MenuItem, ListItemIcon, ListItemText, List, ListItem, IconButton, Alert, Divider, Button
} from '@mui/material'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import PetsIcon from '@mui/icons-material/RocketSharp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';

import makeStyles from '@mui/styles/makeStyles';
import { askBeam } from '../api'
import { maxHeight } from '../height';
import { useNavigate } from 'react-router-dom';
import { getStorage, logoutStorage } from '../utils/storage';
import { handleChat } from '../firebase';
import { load } from '../utils/storage';

const useStyles = makeStyles((theme: any) => {
  return {
    wrapper: {
      display: "flex",
      width: "100%",
      height: maxHeight("0"),
    },
    leftSection: {
      backgroundColor: theme.palette.primary.main,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      color: "white",
    },
    rightSection: {
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      height: "100%",
      color: "white",
      padding: "20px 20px",
      overflow: "auto",
    },
    nonDrawerSection: {
      width: 400,
      [theme.breakpoints.down(2000)]: {
        width: 250,
      },
      [theme.breakpoints.down(1050)]: {
        width: 160,
      },
      [theme.breakpoints.down('md')]: {
        display: "none",
      },
    },
    drawerSection: {
      width: 400,
      height: "100%",
      [theme.breakpoints.down(2000)]: {
        width: 250,
      },
    },
    hi9Logo: {
      marginTop: 5,
      width: 70,
      [theme.breakpoints.down(1050)]: {
        width: 70,
      },
      [theme.breakpoints.down('md')]: {
        width: 70,
      },
    },
    popularPrompt: {
      border: "1px solid grey",
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
      borderRadius: 5,
      padding: "15px 10px",
      marginBottom: 10,
      cursor: "pointer",
    },
    middleSection: {
      height: "100%",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    inputWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 15,
    },
    questionInput: {
      maxWidth: 400,
      marginRight: 10,
      [theme.breakpoints.down(550)]: {
        width: 250,
      },
    },
    widWrapper: {
      flexGrow: 1,
      display: "flex",
      // justifyContent: "center",
      alignItems: "center",
      padding: "0 50px",
      [theme.breakpoints.down(550)]: {
        padding: "0 25px"
      },
    },
    bgWrapper: {
      backgroundColor: theme.palette.secondary.main,
      maxWidth: 800,
      [theme.breakpoints.down(550)]: {
        marginTop: 20,
        marginBottom: 20,
      },
      padding: "30px 20px",
      margin: "0 auto",
    },
    widTitle: {
      marginBottom: 20,
      fontWeight: "bold",
    },
    widText: {
      backgroundColor: theme.palette.secondary.main,
      padding: "15px 40px",
      width: 500,
      margin: "20px 0px",
      borderRadius: 5,
      [theme.breakpoints.down(550)]: {
        width: 300,
      },
    },
    burgerMenuIcon: {
      cursor: "pointer",
      display: "none",
      [theme.breakpoints.down('md')]: {
        display: "block",
      },
    },
    topWrapper: {
      marginTop: 5,
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down('md')]: {
        justifyContent: "space-between"
      },
    },
    messagesWrapper: {
      marginTop: 30,
      paddingBottom: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "left",
      overflowY: "auto",
    },
    message: {
      padding: "15px 40px",
      width: 500,
      margin: "5px 0px",
      borderRadius: 5,
      [theme.breakpoints.up(2500)]: {
        width: 1200,
      },
      [theme.breakpoints.down(2500)]: {
        width: 1000,
      },
      [theme.breakpoints.down(2000)]: {
        width: 800,
      },
      [theme.breakpoints.down(1500)]: {
        width: 500,
      },
      [theme.breakpoints.down(550)]: {
        width: 300,
      },
    },
    userMessage: {
      backgroundColor: theme.palette.secondary.main,
    },
    botMessage: {
      backgroundColor: "#a0d6d9",
    },
    errorMessage: {
      backgroundColor: "#d9a0a0",
    },
    pacifierMessage: {
      padding: "15px",
    },
    historyWrapper: {
      /* width */
      "&::-webkit-scrollbar": {
        width: 5
      },
  
      /* Track */
      "&::-webkit-scrollbar-track": {
        background: theme.palette.primary.main,
      },
  
      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        background: "#888"
      },

      /* Handle on hover */
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555"
      },
    }
  }
});

import moment from 'moment';
moment().format();

const VERSION = "4.0.6" // for backend v6

const POPULAR_PROMPTS = [
  "Our vision is that in three years time GroundWaves will have made an impact across the globe with haptic shoes, and supplying other companies with our actuator technology under licence.",
]
const DISCORD_LINKS = {
  global: "https://discord.gg/y9Gwk73puD",
  data: "https://discord.gg/9tzyuqwvgh",
}

const BASE_URL = "https://www.hi9.io"

function LeftSideSection(props: any) {
  const classes = useStyles();

  return (
    <Box className={`${classes.leftSection}`}>
      <Box sx={{ pt: 2, overflowY: "scroll" }} className={classes.historyWrapper}>
        {
          props.history.length > 0 && <Box>
            <List dense={true}>
              {props.history.map((chat: any, index: number) => {
                return (<ListItem
                  key={index}
                  sx={{ cursor: "pointer" }}
                  onClick={() => { props.prevChat(chat) }}
                /* secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                } */
                >
                  <ListItemIcon sx={{ minWidth: 35 }}>
                    <PetsIcon sx={{ color: "white" }} fontSize="small" />
                  </ListItemIcon>
                </ListItem>)
              })}
            </List>
          </Box>
        }
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
        <Divider sx={{ borderColor: "white" }} />
        <Box sx={{ width: "100%" }}>
        </Box>
      </Box>
    </Box>
  )
}

function RightSideSection(props: any) {
  const classes = useStyles();

  return (
    <Box className={`${classes.rightSection}`}>
      <Box sx={{ pb: 2 }}>
        <TextSizeTypography fontSize={`${1.5 * props.zoom}rem`} variant='h5' sx={{ mb: 2 }}>
          Popular Prompts
        </TextSizeTypography>
        {POPULAR_PROMPTS.map((prompt) => (
          <Box key={prompt} className={classes.popularPrompt} onClick={() => { props.askQuestion(prompt) }}>
            <TextSizeTypography fontSize={`${props.zoom}rem`}>“{prompt}”</TextSizeTypography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

function HTMLRenderer(props: any) {
  const { htmlContent } = props
  return <Typography component="div" dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

function TextSizeTypography(props: any) {
  return props.html ? <Typography component="div" className={props.classes} sx={{ fontSize: props.fontSize || "1rem" }} dangerouslySetInnerHTML={{ __html: props.children }} /> :
    <Typography className={props.classes} sx={{ fontSize: props.fontSize || "1rem" }}>
      {props.children}
    </Typography>
}

const MAX_MONTHLY_QUESTIONS = load("MAX_MONTHLY_QUESTIONS", {value:500}).value

export default function Home(props: any) {
  const classes = useStyles();
  const navigate = useNavigate()

  //#region state
  const [drawers, setDrawers] = React.useState({
    left: false,
    right: false,
  });

  const [dropdownAnchor, setDropdownAnchor] = React.useState(null);
  const open = Boolean(dropdownAnchor);

  const [zoom, setZoom] = React.useState(1);

  const [question, setQuestion] = React.useState("");
  const [messages, setMessages] = React.useState<any[]>([]);

  const [sessionId, setSessionId] = React.useState("");
  const [memory, setMemory] = React.useState<any>([]);

  const [history, setHistory] = useState<any>([])
  const [limited, setLimited] = useState(true)
  //#endregion
  //#region functions
  const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawers({ ...drawers, [anchor]: open });
  };

  const askQuestion = async (prompt: string | null = null) => {

    if (!prompt && !question && question.trim() === "") return

    if (drawers.right) setDrawers({ ...drawers, right: false })

    // alert(prompt || question)
    const questionObj = { text: `${prompt || question}`, type: "user" }
    setMessages([questionObj, { type: "pacifier" }, ...messages])
    setQuestion("")

    let session = sessionId
    if (!session) {
      session = 'xxxx-xxxx'.replace(/[x]/g, (c) => {
        const r = Math.floor(Math.random() * 16);
        return r.toString(16);
      });
      setSessionId(session)
    }
    try {

      const result = await askBeam({
        question: prompt || question,
        sessionId: session,
        memory: JSON.stringify(memory),
        voice: "BidBooter",
        uid: "BidBooter",
        section: "bid_answer",
	mode: props.mode,
      })
      // console.log(result)

      const botObj = { text: `${result.answer.replaceAll("\n", "<br/>")}`, type: "bot" }
      setMessages([questionObj, botObj, ...messages])
      setMemory(result.memory)

      // Update chat
      const message = {
        question: prompt || question,
        answer: result.answer,
      }
      const chat = await handleChat("BidBooter", session, message)
      console.log(chat)

      // Update history
      if (!sessionId) {
        console.log("new")
        const updatedHistory = history.concat([chat])
        setHistory(updatedHistory)
      } else {
        setHistory((chats: any) => {
          const updatedHistory = chats.map((chatObj: any) => {
            //if (chatObj.chatId !== session) return chatObj

            return chat
          })

          return updatedHistory
        })
      }
    } catch (error: any) {
      const errorObj = { text: `Error: ${error.message}`, type: "error" }
      setMessages([questionObj, errorObj, ...messages])
    }

  }

  function smallerText() {
    setZoom(Math.max((+zoom - .1), 0.8)) // 0.8 is the minimum
  }
  function biggerText() {
    setZoom(Math.min((+zoom + .1), 1.5)) // 1.5 is the maximum
  }
  function resetText() {
    setZoom(1)
  }

  const handleClickMore = (event: any) => {
    setDropdownAnchor(event.currentTarget);
  };

  const handleCloseMoreDropdown = () => {
    setDropdownAnchor(null);
  };

  const clearChat = () => {
    setQuestion("")
    setMemory([])
    setMessages([])
    setSessionId("")
    handleCloseMoreDropdown()
  }

  const handlePrevChat = (chat: any) => {
    console.log(chat)
    if (!chat) return

    let messages: any[] = []
    let memory: any[] = []
    chat.messages.map((message: any) => {
      const { question, answer } = message
      messages = [
        { text: `${question}`, type: "user" },
        { text: `${answer.replaceAll("\n", "<br/>")}`, type: "bot" }
      ].concat(messages)

      memory = memory.concat([
        {
          content: question,
          role: "user"
        },
        {
          content: answer,
          role: "assistant"
        },
      ])
    })
    setMessages(messages)
    //setSessionId(chat.chatId)
    //setMemory(memory.slice(-6))
  }

  const handleLogout = () => {
  }
  //#endregion

  return (
    <Box className={classes.wrapper} >
      <Box className={classes.nonDrawerSection}>
        <LeftSideSection
          zoom={zoom} user={{uid:"bidBooster"}} logout={handleLogout}
          history={history} prevChat={handlePrevChat}
        />
      </Box>

      <Box className={classes.middleSection} /* style={{ zoom }} */>
        <Box className={classes.topWrapper}>
          <PetsIcon sx={{ ml: 1 }}
            className={classes.burgerMenuIcon}
            color="primary" fontSize="large"
            onClick={toggleDrawer('left', true)}
          />
          <img src={Logo} alt="Logo" style={{ width: zoom * 100 }} />
          <PetsIcon sx={{ mr: 1 }}
            className={classes.burgerMenuIcon}
            color="primary" fontSize="large"
            onClick={toggleDrawer('right', true)}
          />
        </Box>

        <Box className={classes.inputWrapper}>
          <MoreVertIcon
            color="primary" fontSize="large"
            sx={{ cursor: "pointer", mr: .5 }}
            onClick={handleClickMore}
          />
          <Menu
            id="basic-menu"
            anchorEl={dropdownAnchor}
            open={open}
            onClose={handleCloseMoreDropdown}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={clearChat}>
              <ListItemIcon>
                <ClearIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ "& span": { fontSize: `${zoom}rem` } }}>
                Clear
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={biggerText}>
              <ListItemIcon>
                <TextIncreaseIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ "& span": { fontSize: `${zoom}rem` } }}>Increase text size</ListItemText>
            </MenuItem>
            <MenuItem onClick={smallerText}>
              <ListItemIcon>
                < TextDecreaseIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ "& span": { fontSize: `${zoom}rem` } }}>Decrease text size</ListItemText>
            </MenuItem>
            <MenuItem onClick={resetText}>
              <ListItemIcon>
                < RestartAltIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ "& span": { fontSize: `${zoom}rem` } }}>Reset text size</ListItemText>
            </MenuItem>
          </Menu>

          <TextField
            fullWidth
            id="question-input"
            label=""
            multiline={true}
            rows={5}
            placeholder="enter your bid description here to answer of the application question"
            value={question}
            onChange={(event) => {
              setQuestion(event.target.value);
            }}
            className={classes.questionInput}
            sx={{
              "& .MuiOutlinedInput-root": {
                fontSize: `${zoom}rem`,
              }
            }}
          />
          <SendOutlinedIcon
            color="primary" fontSize="large"
            sx={{ cursor: "pointer" }}
            onClick={() => askQuestion()}
          />
        </Box>

        {/* {error && <Alert severity="error">{error}</Alert>} */}

        {messages.length ? (
          <Box className={classes.messagesWrapper}>
            {messages.map((message, index) => {
              switch (message.type) {
                case "user":
                  return (
                    <Box key={index} className={`${classes.userMessage} ${classes.message}`}>
                      <TextSizeTypography fontSize={`${zoom}rem`}>{message.text}</TextSizeTypography>
                    </Box>
                  )
                case "bot":
                  return (
                    <Box key={index} className={`${classes.botMessage} ${classes.message}`}>
                      <TextSizeTypography fontSize={`${zoom}rem`} html={true}>{message.text}</TextSizeTypography>
                    </Box>
                  )
                case "pacifier":
                  return (
                    <Box key={index} className={`${classes.pacifierMessage}`}>
                      <CircularProgress size={40} />
                    </Box>
                  )
                case "error":
                  return (
                    <Box key={index} className={`${classes.errorMessage} ${classes.message}`}>
                      <TextSizeTypography fontSize={`${zoom}rem`} html={true}>{message.text}</TextSizeTypography>
                    </Box>
                  )
              }
            })}
          </Box>
        ) : (<div />)}
      </Box>

      <Box className={classes.nonDrawerSection}>
        <RightSideSection
          askQuestion={(prompt: string) => { askQuestion(prompt) }}
          zoom={zoom}
        />
      </Box>

      <Drawer
        anchor="left"
        open={drawers["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <Box className={classes.drawerSection}>
          <LeftSideSection
            zoom={zoom} user={{uid:"bidBooster"}} logout={handleLogout}
            history={history} prevChat={handlePrevChat}
          />
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        open={drawers["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <Box className={classes.drawerSection}>
          <RightSideSection
            askQuestion={(prompt: string) => { askQuestion(prompt) }}
            zoom={zoom}
          />
        </Box>
      </Drawer>
    </Box >
  )
}
