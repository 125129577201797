
export const PROFESSIONAL_PLAN = "professional"
export const FREE_PLAN = "free"
export const STARTER_PLAN = "starter"
export const EXPERT_PLAN = "expert"
export const ENTERPRISE_PLAN = "enterprise"

export type TypeOfPlans = "free" | "starter" | "professional" | "expert" | "enterprise"
export const typeOfPlans = ["free", "starter", "professional", "expert", "enterprise"] as TypeOfPlans[]

//Get from firebase the pricing table
export const PLAN_LIMIT_RESPONSES_SAVED = "chats"
export const PLAN_LIMIT_users = "users"

export interface PricingTable {
    free:         Plan;
    starter:      Plan;
    professional: Plan;
    expert:       Plan;
    enterprise:   Plan;
}

export interface Plan {
    id:            string;
    limits:        Limits;
    name:          string;
    rates:         Rates;
    support:       string[];
    paymentLinks?: PaymentLinks;
    default?:      boolean;
    recommended?:  boolean;
}

interface PaymentLinks {
    GBP: PaymentLinksGBP;
}

interface PaymentLinksGBP {
    monthly: string;
    yearly:  string;
}

interface Rates {
    GBP: RatesGBP;
}

interface RatesGBP {
    fixed:   boolean;
    monthly: string;
    yearly:  string;
}

interface Limits {
    monthly: Monthly;
}

interface Monthly {
    chats: number | "unlimited";
}

export async function getPricingTable(): Promise<PricingTable> {
  const response = await fetch('https://beam-383508-default-rtdb.europe-west1.firebasedatabase.app/.json');
  console.log(response);
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message)
  }
  return await response.json()
}


export async function checkLimitsReached(plan: TypeOfPlans = "free", total: number) {
  return getPricingTable().then((pricingTable:PricingTable) => {
    if (!pricingTable[plan]){
      // logOut()
      // setUser(null)
      // window.location.href = '/'
      return null
    }

    const limits = pricingTable[plan].limits.monthly
    // console.log(type, plan, total, limits[type])
    if (limits.chats === "unlimited") {
      return 100
    }
    return limits.chats - total
  })
}
