import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import {
  Box, Button, Typography, Card, CardContent, Divider, Radio,
  Grid, Switch, Snackbar
} from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ENTERPRISE_PLAN, FREE_PLAN, getPricingTable, TypeOfPlans, PricingTable, typeOfPlans } from './pricingTable';

import makeStyles from '@mui/styles/makeStyles';
import { getUser, setUser, save, load } from '../utils/storage';
import { updateSubscription } from '../utils/stripe';
import { auth } from '../firebase';
const useStyles = makeStyles(_ => ({
  wrapper: {
    width: "100%",
    padding: "0 20px",
  },
}))

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SubscriptionPlans() {
  const classes = useStyles();

  const navigate = useNavigate();

  const [currentPlan, setCurrentPlan] = useState(FREE_PLAN as TypeOfPlans)
  const [currentInterval, setCurrentInterval] = useState("month")

  const [chosenPlan, setChosenPlan] = useState(FREE_PLAN as TypeOfPlans)
  const [chosenInterval, setChosenInterval] = useState("month")

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const [loading, setLoading] = useState(false)
  const [orderPlaced, setOrderPlaced] = useState(false)
  const [pricingTable, setPricingTable] = useState({

  } as PricingTable)

  useEffect(() => {
    const user = getUser()


    getPricingTable().then((pricingTable) => {
      setPricingTable(pricingTable)
      if (user.plan) {
        setCurrentPlan(user.plan)
      }
      const saved = load("paying for plan", {interval: "month", plan: {name:""}, links: {}}) as {interval: "month" | "year", plan: {name: string}, links: object}
      if (saved.plan.name) {
        // placed order
        setOrderPlaced(!!saved.plan.name)
      }
    })
    // console.log("user", user.plan)
    if (user && user.plan) {

      setCurrentPlan(user.plan || FREE_PLAN)
      setChosenPlan(user.plan || FREE_PLAN)

      setChosenInterval(user.interval || "month")
      setCurrentInterval(user.interval || "month")
    }
  }, [])

  const handleConfirmPlan = () => {
    getPricingTable().then((pricingTable) => {
      const plan = pricingTable[chosenPlan]

      if (chosenPlan === ENTERPRISE_PLAN && currentPlan !== ENTERPRISE_PLAN) {
        window.location.href = "mailto:wo@hi9.io"
      } else if (currentPlan === FREE_PLAN && chosenPlan === FREE_PLAN) {
        // No changes
        navigate("/")
      } else if (currentPlan !== chosenPlan || currentInterval !== chosenInterval) {
        setLoading(true)
	console.log("handleConfirmPlan", chosenPlan, chosenInterval)
        const interval = chosenInterval === "month" ? "month" : "year"
        /* if (plan.paymentLinks && plan.paymentLinks.GBP) {
          const links = plan.paymentLinks.GBP
          const link = (chosenInterval === "year" ? links.yearly : links.monthly) as string
          save("paying for plan", {plan, interval, link})
          return window.location.href = link
        } */
        // Generate token
        // TODO more to log in or page next load
        //
	
        const authUser = auth.currentUser
        if (!authUser) return
        authUser.getIdToken().then((token) => {
          try {
            updateSubscription({
              plan: chosenPlan,
              interval,
            }, token).then((result) => {

              console.log("result", result)
              if (result.checkout && plan.paymentLinks && plan.paymentLinks.GBP) {
                const links = plan.paymentLinks.GBP
                const link = (chosenInterval === "year" ? links.yearly : links.monthly) as string
                window.location.href = link
              } else {
                setSuccess(result.message)
      
                setCurrentPlan(chosenPlan)
                setCurrentInterval(chosenInterval)
    
                setUser({ ...(getUser() || {}), plan: chosenPlan, interval, token })
              }

              setLoading(false)
            }).catch((error) => {
              console.log("error", error)
              setError(error.message)
              setLoading(false)
            })
          } catch (error: any) {
            setError(error.message)
            setLoading(false)
          }
        })
      } else {
        console.log("no changes")
        // No changes
        navigate("/")
      }
    })
  }
  function PlanCard(props: any) {
    const { plan } = props
    return (
      <Card key={plan.id} sx={{ mb: 1.5, backgroundColor: "#f6f0fd" }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Radio
              disabled={loading}
              checked={chosenPlan === plan.id}
              onChange={() => { setChosenPlan(plan.id) }}
              value={plan.id}
              name={"radio-buttons-" + plan.id}
              inputProps={{ 'aria-label': plan.name }}
              size="medium"

              sx={{ p: 0 }}
            />
          </Box>
          {plan.recommended &&
            <span style={{ fontSize: 12, backgroundColor: "lightgreen", padding: 6, borderRadius: 5, marginBottom: 5, marginRight: 5, textTransform: "uppercase" }}>
              Recommended
            </span>}
          {currentPlan === plan.id &&
            <span style={{ fontSize: 12, backgroundColor: "lightgray", padding: 6, borderRadius: 5, marginBottom: 5, textTransform: "uppercase" }}>
              Current
            </span>}
          <Typography sx={{ fontSize: 15, fontWeight: "bold", mt: 1 }}>{plan.name}</Typography>
          <Typography>
            <span style={{ fontSize: 30 }}>{chosenInterval === "year" ? plan.rates.GBP.yearly : plan.rates.GBP.monthly}</span>
            {plan.rates.GBP.fixed && <span style={{ fontSize: 15 }}>{" / " + chosenInterval}</span>}
          </Typography>
          {
            ![FREE_PLAN, ENTERPRISE_PLAN].includes(plan.id) && "+ VAT"
          }
          {/* <Typography sx={{ fontSize: 13, textAlign: "left" }}>Per month</Typography> */}
          <ul style={{ textAlign: "left", fontSize: 13, marginTop: 10 }}>
            <li>
              <Typography sx={{ fontSize: 13 }}>{plan.limits.monthly.chats} messages </Typography>
            </li>
          </ul>
          <Divider />
          <ul style={{ textAlign: "left", fontSize: 13 }}>
            {plan.support.map((text: string) => {
              return (
                <li key={text}>
                  <Typography sx={{ fontSize: 13 }}>{text}</Typography>
                </li>
              )
            })}
          </ul>
        </CardContent>
      </Card>
    )
  }

  return (
    <Box className={classes.wrapper}
      sx={{ display: { sm: "flex" }, flexDirection: { sm: "column" }, alignItems: { sm: "center" }, }}
    >
      <Box component="form" noValidate autoComplete="on" sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", my: 2 }}>
          <ArrowBackIcon sx={{ fontSize: 20, mr: 1, cursor: "pointer" }} onClick={() => { navigate("/") }} />
          <Typography variant='h5' sx={{ fontWeight: "bold" }}>Choose your plan</Typography>
        </Box>

        <Grid component="label" container alignItems="center" spacing={1} justifyContent="center" >
          <Grid item>
            <Typography variant="subtitle2">Monthly</Typography>
          </Grid>
          <Grid item>
            <Switch
              disabled={loading}
              checked={chosenInterval === "year"}
              onChange={(event) => {
                const newInterval = event.target.checked ? "year" : "month"
                setChosenInterval(newInterval)
              }}
              size="small"
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">Yearly</Typography>
          </Grid>
        </Grid>

        <Box sx={{ mt: 1 }}>
          {
            typeOfPlans.map((type: TypeOfPlans) => {
              const plan = pricingTable[type]
              console.log("plan", plan)
              if (plan) return <PlanCard key={type} plan={plan} />
            })
          }
        </Box>

        <Typography variant="caption">* Fair use</Typography>

        <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
          {/*orderPlaced && <Typography sx={{ color: "green" }}>Order placed</Typography>*/}
          <Button size="large" sx={{ my: 1, minWidth: 200 }} variant="contained"
            onClick={handleConfirmPlan} disabled={loading}
          >
            {chosenPlan === ENTERPRISE_PLAN && currentPlan !== ENTERPRISE_PLAN ? "Contact Us" : "Continue"}
          </Button>
        </Box>

      </Box>

      {error && <Snackbar open={error !== null} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>}
      {success && <Snackbar open={success !== null} autoHideDuration={6000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>}
    </Box>
  )
}
